import {ensureArray} from '../../common/ensure.js'
import {
  KIT_VIEW_PARENTS,
  KIT_VIEW_BOTH,
  KIT_VIEW_COMPONENTS,
} from '../../common/constants/Abode.js'
import Checkbox from '../../common/components/Form/Checkbox.js'
import Select from '../../common/components/Form/Select.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {showEditMockOrdersModal} from './Modals/EditMockOrdersModal.js'
import {showEditMockProfilesModal} from './Modals/EditMockProfilesModal.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from './mockAbodeFunctions.js'
import {useSelector} from '../../store.js'

export default function MockAbodeLabelOptions() {
  const query = useSelector(querySelector)
  const docs = ensureArray(query.docs)
  const orderNumbers = ensureArray(query.order)
  const profileIDs = ensureArray(query.template)
  const mockParams = JSON.parse(query.mock || '{}')
  const includeShippingLabel = docs.includes('shippinglabel')
  const includePackingList = docs.includes('packing')
  const includePickList = docs.includes('pick')

  return (
    <>
      <div>
        <ButtonLink onClick={() => showEditMockOrdersModal()}>
          {orderNumbers.length === 0
            ? 'No orders'
            : orderNumbers.length === 1
              ? orderNumbers[0]
              : `${orderNumbers.length} Orders`}
        </ButtonLink>
      </div>
      <div>
        <ButtonLink onClick={() => showEditMockProfilesModal()}>
          {profileIDs.length === 0
            ? 'No profiles'
            : profileIDs.length === 1
              ? `Common profile (${profileIDs[0]})`
              : 'Unique profile per order'}
        </ButtonLink>
      </div>
      <Checkbox
        label="Use real profiles"
        name="mock_abode__use_real_packing_lists"
        checked={mockParams.use_real_packing_lists || false}
        onChange={(checked) =>
          mockAbodeUpdateResourceQuery({
            mock: {...mockParams, use_real_packing_lists: checked},
          })
        }
      />
      <Select
        label="Layout"
        name="mock_abode__layout"
        value={query.layout}
        onChange={(value) => mockAbodeUpdateResourceQuery({layout: value})}
        className="margin-bottom-20"
      >
        <option value="fullpage">fullpage</option>
        <option value="thermal">thermal</option>
        <option value="two_per_page">two_per_page</option>
        <option value="14668_taste_of_texas">14668_taste_of_texas</option>
        <option value="14668_taste_of_texas_v2">14668_taste_of_texas_v2</option>
        <option value="571053_just_health_shops">
          571053_just_health_shops
        </option>
      </Select>
      <Checkbox
        label="Attach Shipping Label"
        name="mock_abode__doc_shipping_label"
        checked={includeShippingLabel}
        onChange={() => {
          const newDocs = ensureArray(docs).filter(
            (doc) => doc !== 'shippinglabel',
          )

          if (!includeShippingLabel) {
            newDocs.push('shippinglabel')
          }

          mockAbodeUpdateResourceQuery({docs: newDocs})
        }}
      >
        <Checkbox
          label="Show Logo on Label"
          name="mock_abode__show_logo_on_label"
          checked={query.show_logo_on_label}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({show_logo_on_label: checked})
          }
        />
      </Checkbox>

      <Checkbox
        label="Display Order Tray Numbers"
        name="mock_abode__show_order_tray"
        checked={query.show_order_tray}
        onChange={(checked) =>
          mockAbodeUpdateResourceQuery({show_order_tray: checked})
        }
      />
      {query.layout === 'two_per_page' && (
        <Select
          label="Placement of Label on Page"
          name="mock_abode__label_position"
          value={
            query.label_left_always
              ? 'left'
              : query.label_right_always
                ? 'right'
                : ''
          }
          onChange={(value) =>
            mockAbodeUpdateResourceQuery({
              label_left_always: value === 'left',
              label_right_always: value === 'right',
            })
          }
          className="margin-bottom-10"
        >
          <option value="">Either side (flows with content)</option>
          <option value="left">Left side always</option>
          <option value="right">Right side always</option>
        </Select>
      )}
      <Checkbox
        label="Break Page on Order"
        name="mock_abode__break_page_on_order"
        checked={query.break_page_on_order}
        onChange={(checked) =>
          mockAbodeUpdateResourceQuery({break_page_on_order: checked})
        }
      />
      <Checkbox
        label="Attach Packing List"
        name="mock_abode__doc_packing"
        checked={includePackingList}
        onChange={() => {
          const newDocs = ensureArray(docs).filter((doc) => doc !== 'packing')

          if (!includePackingList) {
            newDocs.push('packing')
          }

          mockAbodeUpdateResourceQuery({docs: newDocs})
        }}
      >
        <Select
          label="Sort By"
          name="mock_abode__packing_list_sort_by"
          value={query.packing_list_sort}
          onChange={(value) =>
            mockAbodeUpdateResourceQuery({packing_list_sort: value})
          }
          className="margin-bottom-10"
        >
          <option value="">Original order lines</option>
          <option value="sku">SKU</option>
          <option value="description">Description</option>
          <option value="location">Location (A→Z)</option>
          <option value="location-reverse">Location (Z→A)</option>
          <option value="quantity-reverse">Quantity (High to Low)</option>
          <option value="quantity">Quantity (Low to High)</option>
        </Select>
        <Select
          label="Packing List Layout"
          name="mock_abode__packing_list_layout"
          value={query.packing_list_layout || ''}
          onChange={(value) =>
            mockAbodeUpdateResourceQuery({packing_list_layout: value})
          }
          className="margin-bottom-10"
        >
          <option value="">Standard</option>
          <option value="571436_bumper_active_joe">
            571436 Bumper Active Joe
          </option>
          <option value="347216_m_i_gardener">347216 MIGardener</option>
          <option value="example_1">Example 1</option>
          <option value="example_2">Example 2</option>
        </Select>
        <Checkbox
          label="Show Price"
          name="mock_abode__show_price"
          checked={query.show_price}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({show_price: checked})
          }
        />
        <Checkbox
          label="Show Barcode"
          name="mock_abode__show_barcode"
          checked={query.show_barcode}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({show_barcode: checked})
          }
        />
        <Checkbox
          label="Show Images"
          name="mock_abode__show_packing_images"
          checked={query.show_packing_list_images}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_packing_list_images: checked,
            })
          }
        />
        <Checkbox
          label="Show Kit Components"
          name="mock_abode__show_packing_kit_components"
          checked={query.packing_list_kit_view_mode !== KIT_VIEW_PARENTS}
          onChange={(checked) => {
            mockAbodeUpdateResourceQuery({
              packing_list_kit_view_mode: checked
                ? KIT_VIEW_BOTH
                : KIT_VIEW_PARENTS,
            })
          }}
        >
          <Select
            name="mock_abode__packing_list_kit_view_mode"
            value={query.packing_list_kit_view_mode}
            onChange={(value) =>
              mockAbodeUpdateResourceQuery({
                packing_list_kit_view_mode: value,
              })
            }
            className="margin-bottom-10"
          >
            <option value={KIT_VIEW_BOTH}>Parents & Components</option>
            <option value={KIT_VIEW_COMPONENTS}>Components Only</option>
          </Select>
        </Checkbox>
        <Checkbox
          label="Show Quantities from Split Orders"
          name="mock_abode__show_sibling_order_quantities"
          checked={query.show_sibling_order_quantities}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_sibling_order_quantities: checked,
            })
          }
        />
        <Checkbox
          label="Show Lines with Zero Quantity"
          name="mock_abode__show_zero_quantity_lines"
          checked={query.show_zero_quantity_lines}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_zero_quantity_lines: checked,
            })
          }
        />
        <Checkbox
          label="Show Shipping Info"
          name="mock_abode__show_shipping_info"
          checked={query.show_shipping_info}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({show_shipping_info: checked})
          }
        />
        <Checkbox
          label="Show Warehouse Location"
          name="mock_abode__show_warehouse_location"
          checked={query.show_warehouse_location}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_warehouse_location: checked,
            })
          }
        />
        <Checkbox
          label="Show Bill-To"
          name="mock_abode__show_bill_to"
          checked={query.show_bill_to}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({show_bill_to: checked})
          }
        />
        <Checkbox
          label="Show Ordoro Product Name"
          name="mock_abode__show_ordoro_product_name"
          checked={query.show_ordoro_product_name}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_ordoro_product_name: checked,
            })
          }
        />
        <Checkbox
          label="Show Serial Numbers"
          name="mock_abode__show_pack_serial_numbers"
          checked={query.show_pack_serial_numbers}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pack_serial_numbers: checked,
            })
          }
        />
        <Checkbox
          label="Show Customer Notes"
          name="mock_abode__show_customer_notes"
          checked={query.show_customer_notes}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_customer_notes: checked,
            })
          }
        />
        <Checkbox
          label="Highlight Qtys Greater Than 1"
          name="mock_abode__highlight_pack_line_qty"
          checked={query.highlight_pack_line_qty}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              highlight_pack_line_qty: checked,
            })
          }
        />
        <Checkbox
          label="Show Additional Details"
          name="mock_abode__show_packing_list_details"
          checked={query.show_packing_list_details}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_packing_list_details: checked,
            })
          }
        />
        <Checkbox
          label="Show SKU Barcode"
          name="mock_abode__show_pack_sku_barcode"
          checked={query.show_pack_sku_barcode}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pack_sku_barcode: checked,
            })
          }
        />
      </Checkbox>
      <Checkbox
        label="Attach Pick List"
        name="mock_abode__doc_pick"
        checked={includePickList}
        onChange={() => {
          const newDocs = ensureArray(docs).filter((doc) => doc !== 'pick')

          if (!includePickList) {
            newDocs.push('pick')
          }

          mockAbodeUpdateResourceQuery({docs: newDocs})
        }}
      >
        <Select
          label="Sort By"
          name="mock_abode__pick_list_sort_by"
          value={query.pick_list_sort}
          onChange={(value) =>
            mockAbodeUpdateResourceQuery({pick_list_sort: value})
          }
          className="margin-bottom-10"
        >
          <option value="sku">SKU</option>
          <option value="description">Description</option>
          <option value="location">Location</option>
          <option value="quantity-reverse">Quantity (High to Low)</option>
          <option value="quantity">Quantity (Low to High)</option>
        </Select>
        <Checkbox
          label="Show Images"
          name="mock_abode__show_pick_images"
          checked={query.show_pick_list_images}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pick_list_images: checked,
            })
          }
        />
        <Checkbox
          label="Show Total Items Count"
          name="mock_abode__show_pick_list_total_items"
          checked={query.show_pick_list_total_items}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pick_list_total_items: checked,
            })
          }
        />
        <Checkbox
          label="Show Total SKU Count"
          name="mock_abode__show_pick_list_total_skus"
          checked={query.show_pick_list_total_skus}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pick_list_total_skus: checked,
            })
          }
        />
        <Checkbox
          label="Show Total Order Count"
          name="mock_abode__show_pick_list_total_orders"
          checked={query.show_pick_list_total_orders}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pick_list_total_orders: checked,
            })
          }
        />
        <Checkbox
          label="Show Serial Numbers"
          name="mock_abode__show_pick_serial_numbers"
          checked={query.show_pick_serial_numbers}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pick_serial_numbers: checked,
            })
          }
        />
        <Checkbox
          label="Highlight Qtys Greater Than 1"
          name="mock_abode__highlight_pick_line_qty"
          checked={query.highlight_pick_line_qty}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              highlight_pick_line_qty: checked,
            })
          }
        />
        <Checkbox
          label="Show POH"
          name="mock_abode__show_pick_list_poh"
          checked={query.show_pick_list_poh}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pick_list_poh: checked,
            })
          }
        />
        <Checkbox
          label="Show SKU Barcode"
          name="mock_abode__show_pick_sku_barcode"
          checked={query.show_pick_sku_barcode}
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              show_pick_sku_barcode: checked,
            })
          }
        />
        <Checkbox
          label="Show Kit Components"
          name="mock_abode__show_pick_kit_components"
          checked={query.pick_list_kit_view_mode !== KIT_VIEW_PARENTS}
          onChange={(checked) => {
            mockAbodeUpdateResourceQuery({
              pick_list_kit_view_mode: checked
                ? KIT_VIEW_BOTH
                : KIT_VIEW_PARENTS,
            })
          }}
        >
          <Select
            name="mock_abode__pick_list_kit_view_mode"
            value={query.pick_list_kit_view_mode}
            onChange={(value) =>
              mockAbodeUpdateResourceQuery({
                pick_list_kit_view_mode: value,
              })
            }
            className="margin-bottom-10"
          >
            <option value={KIT_VIEW_BOTH}>Parents & Components</option>
            <option value={KIT_VIEW_COMPONENTS}>Components Only</option>
          </Select>
        </Checkbox>
      </Checkbox>
    </>
  )
}
