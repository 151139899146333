import {ensureArray} from '../../common/ensure.js'
import Checkbox from '../../common/components/Form/Checkbox.js'
import Select from '../../common/components/Form/Select.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {showEditMockRMAsModal} from './Modals/EditMockRMAsModal.js'
import {showEditMockProfilesModal} from './Modals/EditMockProfilesModal.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from './mockAbodeFunctions.js'
import {useSelector} from '../../store.js'

export default function MockAbodeRMALabelOptions() {
  const query = useSelector(querySelector)
  const referenceIDs = ensureArray(query.reference_id)
  const profileIDs = ensureArray(query.template)
  const mockParams = JSON.parse(query.mock || '{}')

  return (
    <>
      <div>
        <ButtonLink onClick={() => showEditMockRMAsModal()}>
          {referenceIDs.length === 0
            ? 'No RMAs'
            : referenceIDs.length === 1
              ? referenceIDs[0]
              : `${referenceIDs.length} RMAs`}
        </ButtonLink>
      </div>
      <div>
        <ButtonLink onClick={() => showEditMockProfilesModal()}>
          {profileIDs.length === 0
            ? 'No profiles'
            : profileIDs.length === 1
              ? `Common profile (${profileIDs[0]})`
              : 'Unique profile per order'}
        </ButtonLink>
      </div>
      <Checkbox
        label="Use real profiles"
        name="mock_abode__use_real_packing_lists"
        checked={mockParams.use_real_packing_lists || false}
        onChange={(checked) =>
          mockAbodeUpdateResourceQuery({
            mock: {...mockParams, use_real_packing_lists: checked},
          })
        }
      />
      <Select
        label="Layout"
        name="mock_abode__layout"
        value={query.layout}
        onChange={(value) => mockAbodeUpdateResourceQuery({layout: value})}
        className="margin-bottom-20"
      >
        <option value="fullpage">fullpage</option>
        <option value="thermal">thermal</option>
        <option value="two_per_page">two_per_page</option>
      </Select>
      <Checkbox
        label="Show Logo on Label"
        name="mock_abode__show_logo_on_label"
        checked={query.show_logo_on_label}
        onChange={(checked) =>
          mockAbodeUpdateResourceQuery({show_logo_on_label: checked})
        }
      />

      {query.layout === 'two_per_page' && (
        <Select
          label="Placement of Label on Page"
          name="mock_abode__label_position"
          value={
            query.label_left_always
              ? 'left'
              : query.label_right_always
                ? 'right'
                : ''
          }
          onChange={(value) =>
            mockAbodeUpdateResourceQuery({
              label_left_always: value === 'left',
              label_right_always: value === 'right',
            })
          }
          className="margin-bottom-10"
        >
          <option value="">Either side (flows with content)</option>
          <option value="left">Left side always</option>
          <option value="right">Right side always</option>
        </Select>
      )}
      <Checkbox
        label="Break Page on Order"
        name="mock_abode__break_page_on_order"
        checked={query.break_page_on_order}
        onChange={(checked) =>
          mockAbodeUpdateResourceQuery({break_page_on_order: checked})
        }
      />
    </>
  )
}
