import {useEffect} from 'react'
import {
  dropshipAppSelector,
  inventoryAppSelector,
  shipAppSelector,
  usesAppsSelector,
} from '../../data/company.js'
import {friendlyNameSelector} from '../../data/me.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showWarehouseModal} from '../Modals/WarehouseModal.js'
import {showEditCartModal} from '../settings/Modals/EditCartModal.js'
import {useSelector} from '../../store.js'
import Salutation from '../DashboardPage/Salutation.js'
import {
  DROPSHIP_APP_PANEL,
  INVENTORY_APP_PANEL,
  SHIP_APP_PANEL,
  showUpgradeModal,
} from '../Modals/UpgradeModal.js'
import {showQuickShipModal} from '../OrderListPage/Modals/QuickShipModal/quickShipModalFunctions.js'
import OrderListModals from '../OrderListPage/Modals/index.js'
import ProductListModals from '../ProductListPage/Modals/index.js'
import {showCreateProductModal} from '../ProductListPage/Modals/CreateProductModal.js'
import {showCreateSupplierModal} from '../ProductListPage/Modals/CreateSupplierModal.js'
import {showScheduleExpertModal} from '../Modals/ScheduleExpertModal.js'
import {showWelcomeTabModal} from '../Modals/WelcomeTabModal.js'
import {canHaveMoreWarehousesSelector} from '../../data/warehouses.js'
import {useSuppliersSelector} from '../../data/suppliers.js'
import {canAddCartsSelector} from '../../data/carts.js'
import {
  SHIP_APP_ADVANCED,
  SHIP_APP_ESSENTIALS,
  SHIP_APP_PREMIUM,
} from '../../common/constants/Apps.js'

export function showWelcomeNavSelector(state) {
  return usesAppsSelector(state)
}

export default function WelcomePage() {
  useEffect(() => {
    document.title = 'Welcome'
  }, [])

  const customerName = useSelector(friendlyNameSelector)
  const shipApp = useSelector(shipAppSelector)
  const inventoryApp = useSelector(inventoryAppSelector)
  const dropshipApp = useSelector(dropshipAppSelector)
  const canHaveMoreWarehouses = useSelector(canHaveMoreWarehousesSelector)
  const useSuppliers = useSelector(useSuppliersSelector)
  const canAddCarts = useSelector(canAddCartsSelector)

  return (
    <div className="wrap--row wrap--dashboard-content padding-top-40 margin-bottom-30">
      <OrderListModals />
      <ProductListModals />
      <div className="medium-11 medium-centered columns">
        <div className="wrap--row margin-bottom-30">
          <div className="medium-8 medium-centered columns">
            <h2 className="fs-04 align-center margin-bottom-20">
              <Salutation name={customerName} />
            </h2>
            <p className="fs-02 lh-md margin-bottom-25 align-center">
              Start using our apps with just a few clicks! The following
              resources are here to help you get up and running in no time.
            </p>
            <div className="margin-auto align-center">
              <a
                className="btn btn--primary btn--primary-alt-ol btn--md margin-right-15"
                href="https://www.youtube.com/user/ordoroteam"
                target="_blank"
                rel="noopener noreferrer"
              >
                ► Watch Tutorial Videos
              </a>
              <ButtonPrimary
                size="md"
                isOutlined
                onClick={() => showScheduleExpertModal()}
              >
                Talk to an Expert
              </ButtonPrimary>
            </div>
          </div>
        </div>
        <div className="wrap--row  flex margin-top-30">
          <div className="medium-4 columns flex margin-top-30">
            <div className="wrap--app-pricing-summary wrap--app-pricing-summary-shipping wrap--app-resource">
              <div className="wrap--app-icon wrap--app-icon-pricing">
                <svg
                  className="icon--app icon--app-shipping icon--app-shipping-01"
                  viewBox="0 0 150 130"
                >
                  <path
                    className="icon__bg-fill--app"
                    d="M102.72,16.95l27.72,48.05-27.72,48.06h-55.44l-27.72-48.06,27.72-48.05h55.44ZM116.25,65l-4.91-8.51h-53.06l5.91-10.23h41.25l-9.82-17.01h-41.25l-20.62,35.75,4.91,8.51h53.05l-5.9,10.24h-41.25l9.81,17.01h41.25l20.63-35.76Z"
                  />
                  <path
                    className="icon__hexagon--app"
                    d="M112.5,0l37.5,65-37.5,65.01H37.5L0,65,37.5,0h75ZM130.44,65l-27.72-48.05h-55.44l-27.72,48.05,27.72,48.06h55.44l27.72-48.06Z"
                  />
                  <polygon
                    className="icon__name--app"
                    points="111.34 56.49 116.25 65 95.62 100.75 54.37 100.75 44.56 83.74 64.19 83.74 85.35 83.74 85.81 83.74 91.71 73.5 58.28 73.5 39.68 73.5 38.66 73.5 33.75 65 54.37 29.25 95.62 29.25 105.44 46.26 85.81 46.26 65.04 46.26 64.19 46.26 58.28 56.49 111.34 56.49"
                  />
                </svg>
              </div>
              <dl className="list list--app-pricing-summary margin-bottom-30">
                <dt className="list__title fs-03 lh-md align-center margin-bottom-5">
                  SHIPPING
                </dt>
                <dd className="list__item align-center margin-bottom-0">
                  {shipApp ? (
                    <strong className="label__callout label__callout--app fs-00">
                      App Enabled
                    </strong>
                  ) : (
                    <ButtonPrimary
                      size="x-sm"
                      isOutlined
                      onClick={() => showUpgradeModal({panel: SHIP_APP_PANEL})}
                    >
                      Enable this App
                    </ButtonPrimary>
                  )}
                </dd>
              </dl>
              <dl className="list list--app-resource margin-bottom-30 hide">
                <dt className="list__title fs-01 lh-md margin-bottom-20">
                  Shipping App Overview
                </dt>
                <dd className="list__item margin-bottom-0">
                  <button className="btn btn--video-overlay">
                    <img
                      className="video-thumb--shipping"
                      src="https://storage.googleapis.com/ordoro-support-site/shipping-cta.svg"
                      alt="Shipping overview video thumbnail"
                    />
                  </button>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-fastest margin-bottom-30">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Fastest Way to Try it Out
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  Use Quick Ship to get a feel for our app — create a shipping
                  label in 4 easy steps:
                </dd>
                <dd className="list__item margin-bottom-15">
                  <ol className="list list--ol">
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Enter a ship from address
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Enter a ship to address
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Choose the carrier and shipping method
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-0">
                      Create the label
                    </li>
                  </ol>
                </dd>
                <dd className="list__item margin-bottom-0 flex">
                  {shipApp ? (
                    <ButtonPrimary
                      size="sm"
                      className="margin-right-7"
                      onClick={() => showQuickShipModal()}
                    >
                      Try Quick Ship
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary
                      size="sm"
                      isOutlined
                      className="margin-right-7"
                      onClick={() => showUpgradeModal({panel: SHIP_APP_PANEL})}
                    >
                      Enable App to Try Quick Ship
                    </ButtonPrimary>
                  )}
                  <div className="flex--justify-col">
                    <a
                      className="btn btn--link no-underline lighter"
                      href="https://support.ordoro.com/how-does-quick-ship-work/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Learn More"
                    >
                      <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                    </a>
                  </div>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-best margin-bottom-30">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Best Way to Get Started
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  Get the best experience by connecting your store for automatic
                  order import. Then:
                </dd>
                <dd className="list__item margin-bottom-15">
                  <ol className="list list--ol">
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Import your orders
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-0">
                      <span className="fs-00 lh-md margin-right-5">
                        Create labels in bulk
                      </span>

                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-to-create-multiple-shipping-labels-in-batch/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                  </ol>
                </dd>

                <dd className="list__item margin-bottom-0 flex">
                  {canAddCarts ? (
                    <ButtonPrimary
                      size="sm"
                      className="margin-right-7"
                      onClick={() => showEditCartModal()}
                    >
                      Connect Your Store
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary
                      size="sm"
                      className="margin-right-7"
                      onClick={() =>
                        showUpgradeModal({
                          panel: SHIP_APP_PANEL,
                          selectedShipApp:
                            shipApp === SHIP_APP_ESSENTIALS
                              ? SHIP_APP_ADVANCED
                              : SHIP_APP_PREMIUM,
                        })
                      }
                    >
                      Upgrade to Add More Channels
                    </ButtonPrimary>
                  )}

                  <div className="flex--justify-col">
                    <a
                      className="btn btn--link no-underline lighter"
                      href="https://support.ordoro.com/getting-started-with-ordoro/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Learn More"
                    >
                      <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                    </a>
                  </div>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-simplest margin-bottom-0">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Simple Ways to Dive In
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  If you don’t have an ecommerce store or if it isn’t compatible
                  with Ordoro, no worries! Easily:
                </dd>
                <dd className="list__item margin-bottom-15">
                  <ul className="list list--square">
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Import your orders from a spreadsheet
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-0">
                      Or create orders one-by-one
                    </li>
                  </ul>
                </dd>
                <dd className="list__item margin-bottom-15 flex">
                  <a
                    className="btn btn--primary btn--sm btn--primary-alt margin-right-7"
                    href="#/dataimport"
                  >
                    Use a Spreadsheet
                  </a>
                  <div className="flex--justify-col">
                    <a
                      className="btn btn--link no-underline lighter"
                      href="https://support.ordoro.com/data-import/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Learn More"
                    >
                      <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                    </a>
                  </div>
                </dd>
                <dd className="list__item margin-bottom-0">
                  <a
                    className="btn btn--primary btn--sm btn--primary-ol"
                    href="#/createorder"
                  >
                    Create an Order
                  </a>
                </dd>
              </dl>
            </div>
          </div>
          <div className="medium-4 columns flex margin-top-30">
            <div className="wrap--app-pricing-summary wrap--app-pricing-summary-inv wrap--app-resource">
              <div className="wrap--app-icon wrap--app-icon-pricing">
                <svg
                  className="icon--app icon--app-inventory icon--app-inventory-01"
                  viewBox="0 0 150 130"
                >
                  <g>
                    <polygon
                      className="icon__bg-fill--app"
                      points="19.56 65 47.28 16.95 102.72 16.95 130.44 65 102.72 113.05 47.28 113.05 19.56 65"
                    />
                    <path
                      className="icon__hexagon--app"
                      d="M112.5,0l37.5,65-37.5,65H37.5L0,65,37.5,0h75ZM130.44,65l-27.72-48.05h-55.44l-27.72,48.05,27.72,48.06h55.44l27.72-48.06Z"
                    />
                  </g>
                  <polygon
                    className="icon__name--app"
                    points="95.63 46.26 95.63 29.27 95.62 29.25 54.37 29.25 54.37 29.26 54.37 46.26 66.5 46.26 66.5 83.74 54.37 83.74 54.37 100.74 54.37 100.75 95.62 100.75 95.63 100.73 95.63 83.74 83.5 83.74 83.5 46.26 95.63 46.26"
                  />
                </svg>
              </div>
              <dl className="list list--app-pricing-summary margin-bottom-30">
                <dt className="list__title fs-03 lh-md align-center margin-bottom-5">
                  INVENTORY
                </dt>
                <dd className="list__item align-center margin-bottom-0">
                  {inventoryApp ? (
                    <strong className="label__callout label__callout--app fs-00">
                      App Enabled
                    </strong>
                  ) : (
                    <ButtonPrimary
                      size="x-sm"
                      isOutlined
                      onClick={() =>
                        showUpgradeModal({panel: INVENTORY_APP_PANEL})
                      }
                    >
                      Enable this App
                    </ButtonPrimary>
                  )}
                </dd>
              </dl>
              <dl className="list list--app-resource margin-bottom-30 hide">
                <dt className="list__title fs-01 lh-md margin-bottom-20">
                  Video Overview of Inventory
                </dt>
                <dd className="list__item margin-bottom-0">
                  <button className="btn btn--video-overlay">
                    <img
                      className="video-thumb--inv"
                      src="https://storage.googleapis.com/ordoro-support-site/inv-mgmt-cta.svg"
                      alt="Inventory overview video thumbnail"
                    />
                  </button>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-fastest margin-bottom-30">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Fastest Way to Try it Out
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  See how inventory management works with Ordoro in just a few
                  steps:
                </dd>
                <dd className="list__item margin-bottom-0">
                  <ol className="list list--ol">
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      <button
                        className="btn--link darkest no-padding border-underline fs-00 lh-md margin-right-5"
                        onClick={() => showCreateProductModal()}
                      >
                        Create a product
                      </button>
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-to-manually-add-products-to-ordoro/#single"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      {canHaveMoreWarehouses ? (
                        <button
                          className="btn--link darkest no-padding border-underline fs-00 lh-md margin-right-5"
                          onClick={() => showWarehouseModal()}
                        >
                          Create a warehouse
                        </button>
                      ) : (
                        <span className="fs-00 lh-md margin-right-5">
                          Create a warehouse
                        </span>
                      )}
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-do-i-setup-warehouses/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      <span className="fs-00 lh-md margin-right-5">
                        Enter stock level for that product
                      </span>

                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-do-i-update-inventory-manually/#edit-qty"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      <a
                        href="#/createorder"
                        className="btn--link darkest no-padding border-underline fs-00 lh-md margin-right-5"
                      >
                        Create an order for that product
                      </a>
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-to-create-a-manual-order/#start"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-0">
                      <span className="fs-00 lh-md margin-right-5">
                        Mark the order as shipped
                      </span>
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-to-mark-an-order-as-shipped/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                  </ol>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-best margin-bottom-30">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Best Way to Get Started
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  Get the most out of Ordoro by connecting your ecommerce store.
                  We’ll take care of importing your products and orders
                  automatically.
                </dd>
                <dd className="list__item fs-00 lh-md margin-bottom-15">
                  <em>
                    Don’t worry — we won’t change any data inside your ecommerce
                    store.
                  </em>
                </dd>

                <dd className="list__item margin-bottom-0 flex">
                  {canAddCarts ? (
                    <ButtonPrimary
                      size="sm"
                      className="margin-right-7"
                      onClick={() => showEditCartModal()}
                    >
                      Connect Your Store
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary
                      size="sm"
                      className="margin-right-7"
                      onClick={() =>
                        showUpgradeModal({
                          panel: SHIP_APP_PANEL,
                          selectedShipApp:
                            shipApp === SHIP_APP_ESSENTIALS
                              ? SHIP_APP_ADVANCED
                              : SHIP_APP_PREMIUM,
                        })
                      }
                    >
                      Upgrade to Add More Channels
                    </ButtonPrimary>
                  )}
                  <div className="flex--justify-col">
                    <a
                      className="btn btn--link no-underline lighter"
                      href="https://support.ordoro.com/getting-started-with-ordoro/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Learn More"
                    >
                      <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                    </a>
                  </div>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-simplest margin-bottom-0">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Simple Ways to Dive In
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  No need to worry if you don’t have an online store or if it
                  doesn’t sync with Ordoro. You can easily:
                </dd>
                <dd className="list__item margin-bottom-15">
                  <ul className="list list--square">
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Import all your products from a spreadsheet
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-0">
                      Or create products one-by-one
                    </li>
                  </ul>
                </dd>
                <dd className="list__item margin-bottom-15 flex">
                  <a
                    className="btn btn--primary btn--sm btn--primary-alt margin-right-7"
                    href="#/dataimport"
                  >
                    Use a Spreadsheet
                  </a>
                  <div className="flex--justify-col">
                    <a
                      className="btn btn--link no-underline lighter"
                      href="https://support.ordoro.com/data-import/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Learn More"
                    >
                      <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                    </a>
                  </div>
                </dd>
                <dd className="list__item margin-bottom-0">
                  <ButtonPrimary
                    size="sm"
                    isOutlined
                    onClick={() => showCreateProductModal()}
                  >
                    Create a Product
                  </ButtonPrimary>
                </dd>
              </dl>
            </div>
          </div>
          <div className="medium-4 columns flex margin-top-30">
            <div className="wrap--app-pricing-summary wrap--app-pricing-summary-dropshipping wrap--app-resource">
              <div className="wrap--app-icon wrap--app-icon-pricing">
                <svg
                  className="icon--app icon--app-dropshipping icon--app-dropshipping-01"
                  viewBox="0 0 150 130"
                >
                  <path
                    className="icon__bg-fill--app"
                    d="M102.72,16.95l27.73,48.05-27.73,48.04h-55.44l-27.72-48.04,27.72-48.05h55.44ZM96.62,65.01l-10.81-18.74h-19.93v37.48h19.93l10.81-18.73Z"
                  />
                  <polygon
                    className="icon__bg-fill--app"
                    points="85.81 46.26 96.62 65.01 85.81 83.74 65.88 83.74 65.88 46.26 85.81 46.26"
                  />
                  <path
                    className="icon__hexagon--app"
                    d="M112.5,0l37.5,65.01-37.5,64.99H37.5L0,65.01,37.5,0h75ZM130.45,65.01l-27.73-48.05h-55.44l-27.72,48.05,27.72,48.04h55.44l27.73-48.04Z"
                  />
                  <path
                    className="icon__name--app"
                    d="M95.34,29.25h-46.75v71.5s15.24,0,15.24,0h31.5l20.63-35.75-20.63-35.75ZM85.52,83.73h-19.93v-37.47h19.93l10.81,18.74-10.81,18.74Z"
                  />
                </svg>
              </div>
              <dl className="list list--app-pricing-summary margin-bottom-30">
                <dt className="list__title fs-03 lh-md align-center margin-bottom-5">
                  DROPSHIPPING
                </dt>
                <dd className="list__item align-center margin-bottom-0">
                  {dropshipApp ? (
                    <strong className="label__callout label__callout--app fs-00">
                      App Enabled
                    </strong>
                  ) : (
                    <ButtonPrimary
                      size="x-sm"
                      isOutlined
                      onClick={() =>
                        showUpgradeModal({panel: DROPSHIP_APP_PANEL})
                      }
                    >
                      Enable this App
                    </ButtonPrimary>
                  )}
                </dd>
              </dl>
              <dl className="list list--app-resource margin-bottom-30 hide">
                <dt className="list__title fs-01 lh-md margin-bottom-20">
                  Video Overview of Dropshipping
                </dt>
                <dd className="list__item margin-bottom-0">
                  <button className="btn btn--video-overlay">
                    <img
                      className="video-thumb--dropshipping"
                      src="https://storage.googleapis.com/ordoro-support-site/dropshipping-cta.svg"
                      alt="Dropshipping overview video thumbnail"
                    />
                  </button>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-fastest margin-bottom-30">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Fastest Way to Try it Out
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  Follow these simple steps to quickly see how dropshipping
                  works in Ordoro!
                </dd>
                <dd className="list__item margin-bottom-0">
                  <ol className="list list--ol">
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      <button
                        className="btn--link darkest no-padding border-underline fs-00 lh-md margin-right-5"
                        onClick={() => showCreateProductModal()}
                      >
                        Create a product
                      </button>
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-to-manually-add-products-to-ordoro/#single"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      {useSuppliers ? (
                        <button
                          className="btn--link darkest no-padding border-underline fs-00 lh-md margin-right-5"
                          onClick={() => showCreateSupplierModal()}
                          disabled={!useSuppliers}
                        >
                          Create a supplier
                        </button>
                      ) : (
                        <span className="fs-00 lh-md margin-right-5">
                          Create a supplier
                        </span>
                      )}
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/set-up-your-suppliers/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      <a
                        href="#/createorder"
                        className="btn--link darkest no-padding border-underline fs-00 lh-md margin-right-5"
                      >
                        Create an order for that product
                      </a>
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-to-create-a-manual-order/#start"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      <span className="fs-00 lh-md margin-right-5">
                        Manually dropship that order to that supplier
                      </span>
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/how-does-manual-dropshipping-work-in-ordoro/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-0">
                      <span className="fs-00 lh-md margin-right-5">
                        Create a rule to auto-dropship all orders containing
                        that product to that supplier
                      </span>
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/fully-automated-dropshipping/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </li>
                  </ol>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-best margin-bottom-30">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Best Way to Get Started
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  For the ultimate Ordoro experience, connect your ecommerce
                  store! We will auto-import your products and orders, then you
                  can easily set up rules to:
                </dd>
                <dd className="list__item margin-bottom-15">
                  <ul className="list list--square">
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Assign suppliers to your products
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-0">
                      Auto-route orders to their respective suppliers
                    </li>
                  </ul>
                </dd>

                <dd className="list__item margin-bottom-0 flex">
                  {canAddCarts ? (
                    <ButtonPrimary
                      size="sm"
                      className="margin-right-7"
                      onClick={() => showEditCartModal()}
                    >
                      Connect Your Store
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary
                      size="sm"
                      className="margin-right-7"
                      onClick={() =>
                        showUpgradeModal({
                          panel: SHIP_APP_PANEL,
                          selectedShipApp:
                            shipApp === SHIP_APP_ESSENTIALS
                              ? SHIP_APP_ADVANCED
                              : SHIP_APP_PREMIUM,
                        })
                      }
                    >
                      Upgrade to Add More Channels
                    </ButtonPrimary>
                  )}
                  <div className="flex--justify-col">
                    <a
                      className="btn btn--link no-underline lighter"
                      href="https://support.ordoro.com/getting-started-with-ordoro/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Learn More"
                    >
                      <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                    </a>
                  </div>
                </dd>
              </dl>
              <dl className="list list--app-resource list--app-resource-simplest margin-bottom-0">
                <dt className="list__title fs-01 lh-md margin-bottom-10">
                  Simple Ways to Dive In
                </dt>
                <dd className="list__item fs-00 lh-md margin-bottom-10">
                  Don’t sweat it if your store isn’t compatible with Ordoro or
                  if you don’t have one! Easily import all your data from a
                  spreadsheet.
                </dd>
                <dd className="list__item margin-bottom-15">
                  <ul className="list list--square">
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Import your products
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-7">
                      Import your suppliers
                    </li>
                    <li className="list__item fs-00 lh-md margin-bottom-0">
                      Import your product-supplier mapping rules
                    </li>
                  </ul>
                </dd>
                <dd className="list__item margin-bottom-0 flex">
                  <a
                    className="btn btn--primary btn--sm btn--primary-alt margin-right-7"
                    href="#/dataimport"
                  >
                    Use a Spreadsheet
                  </a>
                  <div className="flex--justify-col">
                    <a
                      className="btn btn--link no-underline lighter"
                      href="https://support.ordoro.com/data-import/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Learn More"
                    >
                      <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                    </a>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="wrap--row margin-top-20 margin-bottom-20">
          <div className="medium-12 medium-centered columns">
            <h3 className="fs-03 border-top margin-top-30 padding-top-30 margin-bottom-10">
              <strong>How can we help?</strong>
            </h3>
          </div>
        </div>
        <div className="wrap--row margin-bottom-20 flex">
          <div className="medium-3 columns flex">
            <dl className="list margin-bottom-20">
              <dt className="list__title fs-01 lh-md margin-bottom-10">
                Get the Support You Need
              </dt>
              <dd className="list__item fs-00 lh-md margin-bottom-15">
                Our stellar team in Austin, Texas knows our software inside and
                out, offering top-notch support and great solutions just for
                you.
              </dd>
              <dd className="list__item margin-bottom-15">
                <ul className="list list--square">
                  <li className="list__item fs-00 lh-md margin-bottom-10">
                    <div className="margin-bottom-3">
                      <strong>Hours:</strong> Mon-Fri 8:30am to 5:00pm CST
                    </div>
                    <div className="margin-bottom-3">
                      <strong>Phone:</strong> (512) 271-9453
                    </div>
                    <div>
                      <strong>Email:</strong>{' '}
                      <a
                        className="btn--link darker"
                        href="mailto:support@ordoro.com"
                        rel="noopener noreferrer"
                      >
                        support@ordoro.com
                      </a>
                    </div>
                  </li>
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://support.ordoro.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      24/7 Online Knowledge Base
                    </a>{' '}
                    →
                  </li>
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://ordoro.statuspage.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ordoro Uptime Status Page
                    </a>{' '}
                    →
                  </li>
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://support.ordoro.com/contact-us/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Submit a Support Request
                    </a>{' '}
                    →
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="medium-3 columns flex">
            <dl className="list margin-bottom-20 divider--left lg">
              <dt className="list__title fs-01 lh-md margin-bottom-10">
                Useful Resources
              </dt>
              <dd className="list__item margin-bottom-15">
                <ul className="list list--square">
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://support.ordoro.com/category/getting-started/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tips for Getting Started
                    </a>{' '}
                    →
                  </li>
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://support.ordoro.com/category/tips-and-tricks/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      General Tips and Tricks
                    </a>{' '}
                    →
                  </li>
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://support.ordoro.com/category/common-errors/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Common Errors to Avoid
                    </a>{' '}
                    →
                  </li>
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://www.youtube.com/user/ordoroteam/videos"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How-To Videos
                    </a>{' '}
                    →
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="medium-3 columns flex">
            <dl className="list margin-bottom-20 divider--left lg">
              <dt className="list__title fs-01 lh-md margin-bottom-10">
                Build Your Own Integration
              </dt>
              <dd className="list__item fs-00 lh-md margin-bottom-15">
                Are you a developer looking to create your own integration with
                the Ordoro API? Take a look at these resources:
              </dd>
              <dd className="list__item margin-bottom-15">
                <ul className="list list--square">
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://docs.ordoro.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      API Docs
                    </a>{' '}
                    →
                  </li>
                  <li className="list__item fs-00 lh-md margin-bottom-7">
                    <a
                      className="btn--link darker"
                      href="https://forums.ordoro.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      API Developers Forum
                    </a>{' '}
                    →
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="medium-3 columns flex">
            <dl className="list margin-bottom-20 divider--left lg">
              <dt className="list__title fs-01 lh-md margin-bottom-10">
                Talk to an Expert
              </dt>
              <dd className="list__item fs-00 lh-md margin-bottom-15">
                Have questions about your account or the different apps we
                offer? Schedule some time with an Ordoro Expert to get answers.
              </dd>
              <dd className="list__item fs-00 margin-bottom-15">
                <ButtonPrimary
                  size="sm"
                  isOutlined
                  onClick={() => showScheduleExpertModal()}
                >
                  Schedule a Call
                </ButtonPrimary>
              </dd>
            </dl>
          </div>
        </div>
        <div className="wrap--row margin-bottom-20">
          <div className="medium-12 medium-centered columns">
            <p className="fs-n0 lh-md align-center border-top padding-top-20 margin-bottom-10">
              <ButtonPrimary
                size="x-sm"
                isOutlined
                onClick={() => showWelcomeTabModal()}
              >
                Hide this page
              </ButtonPrimary>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
